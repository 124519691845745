/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: wang hai yang
 * @LastEditTime: 2023-05-17 09:31:36
 * @Description: 审核管理模块接口
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const PREFIX = "/school/schcourseware"; // 资源
const PREFIX2 = "/school/schpaper"; // 试卷
const PREFIX3 = "/school/teauser/"; // 教师

/* 资源 */

/*******
 * @Descripttion: 资源(审核)列表【教师共享，学校审核】
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $auditCoursewareList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX}/examine_list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 资源(共享)列表【学校共享，平台审核】
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $shareCoursewareList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX}/share_list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 资源(审核)详情【教师共享，学校审核】
 * @Author: Dyf
 * @param {number} id 课件id
 * @return {*}
 */
export async function $auditCoursewareDetail(id) {
  try {
    return await HTTP.post(`${PREFIX}examine/info`, { id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 资源(共享)详情【学校共享，平台审核】
 * @Author: Dyf
 * @param {number} plcou_exa_id 课件id
 * @return {*}
 */
export async function $shareCoursewareDetail(plcou_exa_id) {
  try {
    return await HTTP.post(`${PREFIX}/share_info`, { plcou_exa_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 审核资源
 * @Author: Dyf
 * @param {number} params.sccou_exa_id 资源id
 * @param {number} params.sccou_exa_type 审核类型 1. 通过   2. 驳回
 * @param {string} params.sccou_reason 驳回理由
 * @return {*}
 */
export async function $auditCourseware(params) {
  try {
    return await HTTP.post(`${PREFIX}examine/examine`, params);
  } catch (error) {
    return error;
  }
}

/* 试卷 */

/*******
 * @Descripttion: 试卷(审核)列表【教师共享，学校审核】
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $auditPaperList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX2}/list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 试卷(分享)列表【学校共享，平台审核】
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $sharePaperList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX2}/share_list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 试卷详情
 * @Author: Dyf
 * @param {number} scpap_id 试卷id
 * @return {*}
 */
export async function $paperDetail(scpap_id) {
  try {
    return await HTTP.post(`${PREFIX2}/info`, { scpap_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 审核试卷
 * @Author: Dyf
 * @param {number} params.scpap_id 试卷id
 * @param {number} params.scpap_examine 审核类型 1. 通过   2. 驳回
 * @param {string} params.scpap_reason 驳回理由
 * @return {*}
 */
export async function $auditPaper(params) {
  try {
    return await HTTP.post(`${PREFIX2}/examine_paper`, params);
  } catch (error) {
    return error;
  }
}

/* 教师 */

/*******
 * @Descripttion: 教师列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $teacherList(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX3}list`,
      { ...params, pagesize },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教师详情
 * @Author: Dyf
 * @param {number} id 教师id
 * @return {*}
 */
export async function $teacherDetail(id) {
  try {
    return await HTTP.post(`${PREFIX3}info`, { id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 审核教师
 * @Author: Dyf
 * @param {number} params.teuser_id 教师id
 * @param {number} params.teuse_examine 审核类型 1. 通过   2. 驳回
 * @param {string} params.teuse_reason 驳回理由
 * @return {*}
 */
export async function $auditTeacher(params) {
  try {
    return await HTTP.post(`${PREFIX3}examine_teacher`, params);
  } catch (error) {
    return error;
  }
}
