<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-06 09:23:19
 * @LastEditTime: 2023-03-06 14:59:40
 * @Descripttion: 试卷共享
-->
<style lang="scss" scoped>
.examination-sharing {
    @include innerPage(40px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 46px), 30px);
    @include pageFoot;
}
</style>

<template>
    <section class="examination-sharing">
        <div class="page-title">
            <h3>试卷共享</h3>
            <div class="search-form">
                <el-form inline :model="searchForm">
                    <el-form-item>
                        <el-select v-model="searchForm.scpap_isshare" placeholder="选择审核状态" clearable @clear="searchData">
                            <el-option label="待审核" :value="15" />
                            <el-option label="已通过" :value="20" />
                            <el-option label="已驳回" :value="30" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model.trim="searchForm.query_word" maxlength="100" placeholder="输入试卷关键字" clearable
                            @clear="searchData" />
                    </el-form-item>
                </el-form>
                <el-button type="custom_primary" size="medium" @click="searchData">查询</el-button>
            </div>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="scpap_title" label="试卷名称" min-width="38.85%" />
                    <el-table-column align="center" label="状态" min-width="16.6%">
                        <template slot-scope="scope">
                            <p class="table-status"
                                :class="{ failed: scope.row.scpap_isshare == 30, success: scope.row.scpap_isshare == 20, warning: scope.row.scpap_isshare == 15 }">
                                {{ scope.row.scpap_isshare == 30 && '已驳回' || scope.row.scpap_isshare == 20 && '已通过' || '待审核'}}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="共享时间" min-width="21.81%">
                        <template slot-scope="scope">
                            {{ scope.row.scpap_issharetime | formatTime }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="22.74%">
                        <template slot-scope="scope">
                            <el-button type="custom_primary" plain size="mini"
                                @click="$router.push({ name: 'AUDIT_EXAMINATION_DETAIL', params: { audit: 2, id: scope.row.scpap_id } })">
                                查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" @pageChange="flippingPage" />
            </div>
        </div>
    </section>
</template>

<script>
import { $sharePaperList } from "@api/audit"
import customPagination from "@comp/customPagination"
export default {
    name: 'audit_examinationSharing',
    components: { customPagination },
    data() {
        return {
            searchForm: {},
            tableData: [], // 表格参数
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                total: 0
            },
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取试卷列表数据 */
        async getData() {
            let params = {
                ...this.searchForm,
                pageindex: this.tablePage.pageIndex
            };
            let { data: res } = await $sharePaperList(params);
            this.tablePage.total = res.allcount;
            this.tableData = res.data;
            this.$forceUpdate();
        },
        /** 搜索 */
        searchData() {
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.getData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
    },
}
</script>